<template>
	<div>
		<SecondaryActionButton @click="showProcessFilter = !showProcessFilter">
			<v-icon>
				mdi-filter
			</v-icon>
			Filter
		</SecondaryActionButton>
		<TopSheet v-model="showProcessFilter">
			<v-sheet>
				<v-card class="d-flex flex-column">
					<v-divider />
					<v-card-text
						class="py-6 overflow-auto"
					>
						<v-row>
							<v-col
								cols="3"
								class="cassie-vertical-md"
							>
								<v-row>
									<v-col
										lg="10"
										sm="5"
										class="cassie-vertical-md"
									>
										<PrecessPurposeDropdown
											v-model="processFilterValues.processPurposeId"
											:items="processPurposes"
										/>
										<PrecessCountryDropdown
											v-if="visibleCountry"
											v-model="processFilterValues.countryId"
											:items="countries"
										/>
									</v-col>
								</v-row>
								<ProcessCountryDirections
									v-model="processFilterValues.processDirections"
								/>
							</v-col>
							<v-col
								cols="3"
								class="cassie-vertical-md"
							>
								<div>
									<div class="text-subtitle-2 font-weight-bold mb-4">
										System
									</div>
									<TextField
										v-model="processFilterValues.system"
										label="System"
									/>
								</div>
								<SpecialPiiCategories
									v-model="processFilterValues.specialPiiCategories"
									:items="specialPiiCategories"
								/>
							</v-col>
							<v-col cols="4">
								<div>
									<div class="text-subtitle-2 font-weight-bold mb-4">
										Files
									</div>
									<ProcessDpaRadios
										:radio-group-value.sync="processFilterValues.dpaFile"
									/>
									<ProcessLegalBasisRadios
										:radio-group-value.sync="processFilterValues.legalBasisFile"
									/>
									<ProcessArticle30Radios
										:radio-group-value.sync="processFilterValues.article30File"
									/>
								</div>
							</v-col>
							<v-col cols="2">
								<ProcessDateRange
									:date-from="processFilterValues.dateFrom"
									:date-to="processFilterValues.dateTo"
									@date-from-change="processFilterValues.dateFrom = $event"
									@date-to-change="processFilterValues.dateTo = $event"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-divider />
					<v-card-actions class="justify-end py-4 px-6">
						<SecondaryActionButton @click="resetFilter">
							Reset to default
						</SecondaryActionButton>
						<v-spacer />
						<PrimaryActionButton @click="applyFilter">
							Apply
						</PrimaryActionButton>
					</v-card-actions>
				</v-card>
			</v-sheet>
		</TopSheet>
	</div>
</template>

<script>
import TextField from '../../../../../../shared/components/text-field.vue'
import SecondaryActionButton from '../../../../../../shared/components/secondary-action-button.vue'
import PrimaryActionButton from '../../../../../../shared/components/primary-action-button.vue'
import TopSheet from '../../../../../../shared/components/top-sheet.vue'
import PrecessPurposeDropdown from './process-purpose-dropdown.vue'
import PrecessCountryDropdown from './process-country-dropdown.vue'
import SpecialPiiCategories from './process-special-pii-categories.vue'
import ProcessDpaRadios from './process-dpa-radios.vue'
import ProcessLegalBasisRadios from './process-legal-basis-radios.vue'
import ProcessArticle30Radios from './process-article-30-radios.vue'
import ProcessDateRange from './process-date-range.vue'
import ProcessCountryDirections from './process-country-directions.vue'

export default {
	components: {
		ProcessCountryDirections,
		TextField,
		SecondaryActionButton,
		PrimaryActionButton,
		PrecessPurposeDropdown,
		PrecessCountryDropdown,
		SpecialPiiCategories,
		ProcessDpaRadios,
		ProcessLegalBasisRadios,
		ProcessArticle30Radios,
		ProcessDateRange,
		TopSheet
	},
	props: {
		processPurposes: {
			type: Array,
			required: true
		},
		visibleCountry: {
			type: Boolean,
			default: true
		},
		countries: {
			type: Array,
			required: true
		},
		specialPiiCategories: {
			type: Array,
			required: true
		},
		filterValues: {
			type: Object,
			required: true
		}
	},
	data () {
		const { filterValues } = this

		return {
			processFilterInitialValues: { ...filterValues },
			processFilterValues: { ...filterValues },
			showProcessFilter: false
		}
	},
	watch: {
		filterValues (value) {
			this.processFilterValues = { ...value }
		}
	},
	methods: {
		resetFilter () {
			this.processFilterValues = { ...this.processFilterInitialValues }
		},
		applyFilter () {
			this.showProcessFilter = false
			this.$emit('submit', this.processFilterValues)
		}
	}
}
</script>
