<template>
	<div>
		<div class="text-subtitle-2 font-weight-bold mb-4">
			Information category
		</div>
		<Dropdown
			item-text="specialPiiCategoryName"
			item-value="specialPiiCategoryId"
			label="Select categories"
			multiple
			persistent-hint
			v-bind="$props"
			v-on="$listeners"
		/>
	</div>
</template>

<script>

import Dropdown from '../../../../../../shared/components/dropdown.vue'

export default {
	components: { Dropdown },
	extends: Dropdown
}
</script>
