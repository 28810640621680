<template>
	<Dropdown
		label="Country"
		item-text="countryName"
		item-value="countryId"
		v-bind="$props"
		:items="countriesWithAll"
		v-on="$listeners"
		@update:search-input="searchInput"
	/>
</template>

<script>
import Dropdown from '../../../../../../shared/components/dropdown.vue'

export default {
	components: {
		Dropdown
	},
	extends: Dropdown,
	props: {
		items: Array
	},
	data () {
		return {
			searchInput: ''
		}
	},
	computed: {
		countriesWithAll () {
			return [
				{ countryName: 'All', countryId: null },
				...this.items
			]
		}
	}
}
</script>
