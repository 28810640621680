<template>
	<SectionCard>
		<template #title>
			Inactive Processes
		</template>
		<template #body>
			<DataTable
				sort-by="name"
				:selected="selected"
				:item-per-page="5"
				:headers="tableHeaders"
				:items="items"
				:disable-sort="false"
				multi-select
				@click:row="$emit('click:row', $event)"
			/>
		</template>
	</SectionCard>
</template>
<script>

import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'

export default {
	components: {
		SectionCard,
		DataTable
	},
	props: {
		items: {
			type: Array,
			default: () => []
		},
		selected: Function
	},
	data () {
		return {
			tableHeaders: [
				{
					value: 'id',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'name',
					text: 'Process Name',
					width: '30%'
				},
				{
					value: 'processPurposeName',
					text: 'Process Purpose',
					width: '30%'
				},
				{
					value: 'lastEditedDate',
					text: 'Last used on',
					width: '30%'
				}
			]
		}
	}
}
</script>
