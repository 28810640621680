<template>
	<SectionCard>
		<template #title>
			Unmapped Cassie Processes
		</template>
		<template #body>
			<DataTable
				:item-per-page="6"
				:headers="headers"
				:items="items"
				:disable-sort="false"
			/>
		</template>
	</SectionCard>
</template>
<script>

import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'

export default {
	components: {
		SectionCard,
		DataTable
	},
	props: {
		items: {
			type: Array,
			default: () => []
		}
	},
	data () {
		return {
			headers: [
				{
					value: 'objectId',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'objectName',
					text: 'Item',
					width: '45%'
				},
				{
					value: 'objectType',
					text: 'Type',
					width: '45%'
				}
			]
		}
	}
}
</script>
