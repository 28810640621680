<template>
	<v-checkbox
		v-bind="$props"
		v-on="$listeners"
	/>
</template>

<script>
import { VCheckbox } from 'vuetify/lib'

export default {
	name: 'checkbox',
	extends: VCheckbox
}
</script>
