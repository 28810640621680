<template>
	<SectionCard>
		<template #title>
			Processes
		</template>
		<template #body>
			<DataTable
				sort-by="name"
				:item-per-page="10"
				:selected="selected"
				:headers="tableHeaders"
				:items="items"
				:disable-sort="false"
				multi-select
				@click:row="$emit('click:row', $event)"
				@current-items="$emit('current-items', $event)"
			>
				<template #item.legend="{item}">
					<div
						:style="{
							width: '40px',
							height: '15px',
							background: item.processColor
						}"
					/>
				</template>
			</DataTable>
		</template>
	</SectionCard>
</template>
<script>

import SectionCard from '../../../../../../shared/components/section-card.vue'
import DataTable from '../../../../../../shared/components/data-table.vue'

export default {
	components: {
		SectionCard,
		DataTable
	},
	props: {
		items: Array,
		selected: Function
	},
	data () {
		return {
			tableHeaders: [
				{
					value: 'id',
					text: 'ID',
					width: '10%'
				},
				{
					value: 'legend',
					text: '',
					width: '10%'
				},
				{
					value: 'name',
					text: 'Process Name',
					width: '20%'
				},
				{
					value: 'processPurposeName',
					text: 'Process Purpose',
					width: '20%'
				},
				{
					value: 'cityName',
					text: 'Location',
					width: '10%'
				},
				{
					value: 'sourceSystemData.sourceSystemName',
					text: 'Data source',
					width: '20%'
				},
				{
					value: 'destinationSystemData.destinationSystemName',
					text: 'Destination',
					width: '10%'
				}
			]
		}
	}
}
</script>
