export const ProcessDirection = {
	Origin: 1,
	Destination: 2,
	ProcLoc: 3,
	CrossBorderOnly: 4
}

export const ProcessFileRadios = {
	All: 1,
	Present: 2,
	NotPresent: 3
}
