<template>
	<ProcessingLayout>
		<template #content>
			<ProcessDashboardFilter
				:filter-values="filterValues"
				:visible-country="visibleCountryFilter"
				:process-purposes="processPurposes"
				:countries="countries"
				:special-pii-categories="specialPiiCategories"
				@submit="submitFilter"
			/>
			<div>
				<v-row>
					<v-col :cols="displayProcessesCumulativeGraph ? 6 : 12">
						<ProcessMap
							:only-current-location="!!activeProcessIds.length"
							:items="selectedItems"
						/>
					</v-col>
					<v-col
						v-show="displayProcessesCumulativeGraph"
						cols="6"
					>
						<ProcessCumulativeChart
							:items="selectedProcesses"
							:chart-api-params="{
								dateFrom: filterValues.dateFrom,
								dateTo: filterValues.dateTo,
								processes: selectedProcesses
							}"
							:date-from="filterValues.dateFrom"
							:date-to="filterValues.dateTo"
							@chart-data="displayChart"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12">
						<ProcessActiveTable
							:selected="isProcessHasSelected"
							:items="filteredProcess"
							@current-items="currentProcessesTableItems = $event"
							@click:row="clickTableRow"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col
						cols="12"
					>
						<ProcessInactiveTable
							:selected="isProcessHasSelected"
							:items="inactiveProcesses"
							@click:row="clickTableRow"
						/>
					</v-col>
				</v-row>
				<v-row>
					<v-col
						cols="12"
					>
						<ProcessUnmappedTable :items="noneProcessAlerts" />
					</v-col>
				</v-row>
			</div>
		</template>
	</ProcessingLayout>
</template>
<script>

import { sub, formatISO } from 'date-fns'
import ProcessingLayout from './processing-layout.vue'
import ProcessActiveTable from './process-tables/process-active-table.vue'
import ProcessInactiveTable from './process-tables/process-inactive-table.vue'
import ProcessUnmappedTable from './process-tables/process-unmapped-table.vue'
import ProcessDashboardFilter from './process-dashboard-filter/process-dashboard-filter.vue'
import ProcessMap from './process-map/process-map.vue'
import ProcessCumulativeChart from './process-chart/process-cumulative-chart.vue'
import { getRandomColorByString } from '../../../../../shared/utils/getRandomColorByString.js'
import { ProcessDirection, ProcessFileRadios } from './process-dashboard-filter/process-dashboard-filter-constants.js'
import { getProcessesAndLookupData } from '../../../../../shared/utils/api/processing.js'

const initialValues = {
	processPurposeId: null,
	countryId: null,
	specialPiiCategories: [],
	system: '',
	dpaFile: ProcessFileRadios.All,
	article30File: ProcessFileRadios.All,
	legalBasisFile: ProcessFileRadios.All,
	dateFrom: formatISO(sub(new Date(), { weeks: 2 }), { representation: 'date' }),
	dateTo: formatISO(new Date(), { representation: 'date' }),
	processDirections: [
		ProcessDirection.ProcLoc,
		ProcessDirection.Origin,
		ProcessDirection.Destination
	]
}

export default {
	components: {
		ProcessingLayout,
		ProcessActiveTable,
		ProcessInactiveTable,
		ProcessUnmappedTable,
		ProcessDashboardFilter,
		ProcessCumulativeChart,
		ProcessMap
	},
	data () {
		return {
			activeProcessIds: [],
			currentProcessesTableItems: [],
			filterValues: initialValues,
			processes: [],
			lookUpData: null,
			displayProcessesCumulativeGraph: false
		}
	},
	computed: {
		countries () {
			return this.lookUpData?.countries ?? []
		},
		processPurposes () {
			return this.lookUpData?.processPurposes ?? []
		},
		specialPiiCategories () {
			return this.lookUpData?.specialPiiCategories ?? []
		},
		noneProcessAlerts () {
			return this.lookUpData?.noneProcessAlerts ?? []
		},
		selectedProcesses () {
			return this.selectedItems.map(selectedItem => {
				return selectedItem.processId
			}).join(',')
		},
		selectedItems () {
			if (!this.activeProcessIds.length) return this.currentProcessesTableItems
			return this.currentProcessesTableItems.filter(tableItem => this.activeProcessIds.includes(tableItem.processId))
		},
		selectedItemsWithDateRange () {
			const checkDateFrom = process => {
				const { dateFrom } = this.filterValues
				return !dateFrom || new Date(process.createdDate) >= new Date(dateFrom)
			}

			const checkDateTo = process => {
				const { dateTo } = this.filterValues
				return !dateTo || new Date(process.createdDate) < new Date(dateTo)
			}
			return this.selectedItems.filter(process => {
				return checkDateFrom(process) && checkDateTo(process)
			})
		},
		visibleCountryFilter () {
			const unarchivedProcesses = this.processes.filter(process => !process.isArchived)
			const countries = this.getProcessCountryIds(unarchivedProcesses)

			return countries.length > 1
		},
		inactiveProcesses () {
			return this.processes.filter(({ isAlert }) => isAlert)
		},
		filteredProcess () {
			const checkPurpose = process => {
				const { processPurposeId } = this.filterValues

				return !processPurposeId || processPurposeId === process.processPurposeId
			}

			const checkInformationCategory = process => {
				const { specialPiiCategories } = this.filterValues

				if (!specialPiiCategories.length) return true
				if (!process.specialPiiCategories) return false

				const processCategoryIds = process.specialPiiCategories.map(({ specialPiiCategoryId }) => specialPiiCategoryId)

				return specialPiiCategories.some(categoryId => processCategoryIds.includes(categoryId))
			}

			const checkSystemName = process => {
				const { system } = this.filterValues

				if (!system.length) return true

				const { sourceSystemData, destinationSystemData } = process

				const lowerSystem = system.toLowerCase()

				const lowerSourceSystemName = sourceSystemData.sourceSystemName.toLowerCase()
				const lowerSourceOrganisationName = sourceSystemData.sourceSystemOrganisationName.toLowerCase()

				const lowerDestinationSystemName = destinationSystemData.destinationSystemName.toLowerCase()
				const lowerDestinationOrganisationName = destinationSystemData.sourceSystemOrganisationName.toLowerCase()

				return lowerSourceSystemName.startsWith(lowerSystem) ||
            lowerSourceOrganisationName.startsWith(lowerSystem) ||
            lowerDestinationSystemName.startsWith(lowerSystem) ||
            lowerDestinationOrganisationName.startsWith(lowerSystem)
			}

			const checkFiles = process => {
				const { dpaFile, legalBasisFile, article30File } = this.filterValues

				const isDpaFileAll = dpaFile === ProcessFileRadios.All
				const isLegalBasisFileAll = legalBasisFile === ProcessFileRadios.All
				const isArticle30FileAll = article30File === ProcessFileRadios.All

				if (isDpaFileAll && isLegalBasisFileAll && isArticle30FileAll) {
					return true
				}

				const dpaFileExists = dpaFile === ProcessFileRadios.Present ? !!process.dpaFileGUID : !process.dpaFileGUID
				const legalBasisFileExists = legalBasisFile === ProcessFileRadios.Present ? !!process.legalBasisFileGUID : !process.legalBasisFileGUID
				const article30FileExists = article30File === ProcessFileRadios.Present ? !!process.article30FileGUID : !process.article30FileGUID

				return (!isDpaFileAll && dpaFileExists) ||
            (!isLegalBasisFileAll && legalBasisFileExists) ||
            (!isArticle30FileAll && article30FileExists)
			}

			const checkProcessDirection = process => {
				const { processDirections, countryId } = this.filterValues

				const destinationFilter = processDirections.includes(ProcessDirection.Destination)
				const originFilter = processDirections.includes(ProcessDirection.Origin)
				const procLocFilter = processDirections.includes(ProcessDirection.ProcLoc)

				let result = !countryId ||
            (destinationFilter && countryId === process.destinationSystemData.countryId) ||
            (originFilter && countryId === process.sourceSystemData.countryId) ||
            (procLocFilter && countryId === process.countryId)

				if (result && processDirections.includes(ProcessDirection.CrossBorderOnly)) {
					result = process.isCrossBorder
				}

				return result
			}

			return this.processes.filter(process => {
				return checkPurpose(process) &&
            checkInformationCategory(process) &&
            checkSystemName(process) &&
            checkFiles(process) &&
            checkProcessDirection(process)
			})
		}
	},
	async created () {
		const { data: { processes, lookUpData } } = await getProcessesAndLookupData()

		this.processes = this.addPropHelpers(processes)
		this.lookUpData = lookUpData
	},
	methods: {
		addPropHelpers (processes) {
			return processes.map(process => ({
				processColor: getRandomColorByString(`${process.processId}${process.name}`),
				...process
			}))
		},
		submitFilter (values) {
			this.filterValues = values
		},
		clickTableRow ({ processId }) {
			const index = this.activeProcessIds.indexOf(processId)

			if (index === -1) {
				this.activeProcessIds.push(processId)
			} else {
				this.activeProcessIds.splice(index, 1)
			}
		},
		isProcessHasSelected (row) {
			return this.activeProcessIds.includes(row.processId)
		},
		getProcessCountryIds (processes) {
			const ids = processes.map(({ countryId }) => countryId)
			return [...new Set(ids)]
		},
		displayChart (displayChart) {
			if (displayChart) {
				this.displayProcessesCumulativeGraph = true
			} else {
				this.displayProcessesCumulativeGraph = false
			}
		}
	}
}
</script>
