<template>
	<v-row align="center">
		<v-col cols="3">
			<span class="text-caption">
				Article 30 file
			</span>
		</v-col>
		<v-col cols="8">
			<RadioGroup
				v-bind="$props"
				:radios="radios"
				v-on="$listeners"
			/>
		</v-col>
	</v-row>
</template>

<script>

import RadioGroup from '../../../../../../shared/components/radio-group.vue'
import { ProcessFileRadios } from './process-dashboard-filter-constants.js'

export default {
	components: {
		RadioGroup
	},
	extends: RadioGroup,
	props: {
		radios: {
			type: Array,
			default: () => [
				{
					label: 'All',
					value: ProcessFileRadios.All
				},
				{
					label: 'Present',
					value: ProcessFileRadios.Present
				},
				{
					label: 'Not present',
					value: ProcessFileRadios.NotPresent
				}
			]
		}
	}
}
</script>
