<template>
	<Dropdown
		label="Purpose"
		item-text="processPurposeName"
		item-value="processPurposeId"
		v-bind="$props"
		:items="purposesWithAll"
		v-on="$listeners"
	/>
</template>

<script>
import Dropdown from '../../../../../../shared/components/dropdown.vue'

export default {
	components: {
		Dropdown
	},
	extends: Dropdown,
	props: {
		items: Array
	},
	computed: {
		purposesWithAll () {
			return [
				{ processPurposeName: 'All', processPurposeId: null },
				...this.items
			]
		}
	}
}
</script>
