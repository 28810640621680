<template>
	<LeafletMap
		ref="processMap"
		:markers="markers"
		:polylines="polylines"
		@ready="onReady"
		@markers:change="onMarkersChanged"
	>
		<template #tooltipContent="{ index }">
			<v-simple-table dense>
				<template #default>
					<tbody>
						<tr>
							<td>
								Name
							</td>
							<td>
								{{ items[index].name }}
							</td>
						</tr>
						<tr>
							<td>
								Process Purpose
							</td>
							<td>
								{{ items[index].processPurposeName }}
							</td>
						</tr>
						<tr>
							<td>
								Location
							</td>
							<td>
								{{ items[index].cityName }}
							</td>
						</tr>
						<tr>
							<td>
								Data source
							</td>
							<td>
								{{ items[index].sourceSystemData.sourceSystemName }}
							</td>
						</tr>
						<tr>
							<td>
								Destination
							</td>
							<td>
								{{ items[index].destinationSystemData.destinationSystemName }}
							</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</template>
	</LeafletMap>
</template>

<script>
import LeafletMap from '../../../../../../shared/components/leaflet-map.vue'

export default {
	components: {
		LeafletMap
	},
	props: {
		items: Array,
		activeItemIds: Array,
		onlyCurrentLocation: {
			type: Boolean,
			default: true
		}
	},
	computed: {
		flagMarker () {
			return {
				color: '#000',
				anchor: [6, 20]
			}
		},
		points () {
			const getSourcePoint = item => {
				const { sourceSystemData } = item
				return [sourceSystemData.sourceSystemCityLatitude, sourceSystemData.sourceSystemCityLongitude]
			}

			const getLocationPoint = item => {
				const { cityLatitude, cityLongitude } = item
				return [cityLatitude, cityLongitude]
			}

			const getDestinationPoint = item => {
				const { destinationSystemData } = item
				return [destinationSystemData.destinationSystemcityLatitude, destinationSystemData.destinationSystemcityLongitude]
			}

			return this.items.map(item => {
				const sourcePoint = getSourcePoint(item)
				const locationPoint = getLocationPoint(item)
				const destinationPoint = getDestinationPoint(item)

				return {
					data: item,
					sourcePoint,
					locationPoint,
					destinationPoint
				}
			})
		},
		markers () {
			const createMarker = (latlng, { icon, anchor, color }) => {
				return {
					latlng,
					icon,
					anchor,
					color
				}
			}

			const result = []

			this.points.forEach(point => {
				result.push(createMarker(point.sourcePoint, { icon: 'mdi-flag-variant-outline', ...this.flagMarker }),
					createMarker(point.locationPoint, { color: point.data.processColor }),
					createMarker(point.destinationPoint, { icon: 'mdi-flag-checkered', ...this.flagMarker }))
			})
			return result
		},
		polylines () {
			return this.points.map(point => {
				return {
					latlngs: [point.sourcePoint, point.locationPoint, point.destinationPoint],
					color: point.data.processColor,
					tooltip: {
						show: true,
						options: {
							sticky: true
						}
					}
				}
			})
		}
	},
	methods: {
		onMarkersChanged () {
			this.$refs.processMap.invalidateSize()
			this.fitMarkerBounds()
		},
		fitMarkerBounds () {
			this.$refs.processMap.fitMarkerBounds()
		},
		onReady () {
			this.fitMarkerBounds()
		}
	}
}
</script>
