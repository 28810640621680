<template>
	<div>
		<div class="text-subtitle-2 font-weight-bold mb-4">
			Process Count Date range
		</div>
		<div class="cassie-vertical-md">
			<DatePicker
				:date="dateFrom"
				:max="dateTo || maxDate"
				small
				@update:date="$emit('date-from-change', $event)"
			/>
			<DatePicker
				:date="dateTo"
				:min="dateFrom"
				:max="maxDate"
				small
				@update:date="$emit('date-to-change', $event)"
			/>
		</div>
	</div>
</template>

<script>
import DatePicker from '../../../../../../shared/components/date-picker.vue'

export default {
	components: {
		DatePicker
	},
	props: {
		dateFrom: {
			type: String
		},
		dateTo: {
			type: String
		}
	},
	computed: {
		maxDate () {
			return new Date().toISOString()
		}
	}
}
</script>
