<template>
	<div>
		<Checkbox
			v-for="item in items"
			:key="item.value"
			:label="item.label"
			:value="item.value"
			:input-value="inputValue"
			hide-details
			@change="$emit('change', $event)"
		/>
	</div>
</template>

<script>

import Checkbox from '../../../../../../shared/components/checkbox.vue'
import { ProcessDirection } from './process-dashboard-filter-constants.js'

export default {
	components: {
		Checkbox
	},
	model: {
		prop: 'inputValue',
		event: 'change'
	},
	props: {
		inputValue: Array
	},
	data () {
		return {
			items: [
				{
					label: 'Origin',
					value: ProcessDirection.Origin
				},
				{
					label: 'Destination',
					value: ProcessDirection.Destination
				},
				{
					label: 'Proc. Loc',
					value: ProcessDirection.ProcLoc
				},
				{
					label: 'Cross border only',
					value: ProcessDirection.CrossBorderOnly
				}
			]
		}
	}
}
</script>
