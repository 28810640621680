<template>
	<LMap
		ref="map"
		:style="style"
		class="leaflet-map"
		@ready="$emit('ready', $event)"
	>
		<LTileLayer :url="url" />
		<div>
			<LMarker
				v-for="(marker, index) in markers"
				:key="index"
				:lat-lng="marker.latlng"
			>
				<LIcon
					:icon-anchor="marker.anchor || defaultMarkerIcon.anchor"
				>
					<v-icon :color="marker.color">
						{{ marker.icon || defaultMarkerIcon.icon }}
					</v-icon>
				</LIcon>
			</LMarker>
		</div>
		<LPolyline
			v-for="(polyline, index) in polylines"
			:key="index"
			:lat-lngs="polyline.latlngs"
			:color="polyline.color"
		>
			<LTooltip
				v-if="polyline.tooltip.show"
				:options="polyline.tooltip.options"
			>
				<slot
					name="tooltipContent"
					:index="index"
				/>
			</LTooltip>
		</LPolyline>
	</LMap>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPolyline, LIcon, LTooltip } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

export default {
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LPolyline,
		LIcon,
		LTooltip
	},
	props: {
		markers: Array,
		polylines: Array,
		height: {
			type: Number,
			default: 400
		}
	},
	data () {
		return {
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
		}
	},
	computed: {
		style () {
			return `height: ${this.height}px`
		},
		defaultMarkerIcon () {
			return {
				icon: 'mdi-map-marker',
				anchor: [13, 20]
			}
		}
	},
	watch: {
		markers () {
			this.$emit('markers:change')
		}
	},
	methods: {
		invalidateSize () {
			this.$refs.map.mapObject.invalidateSize()
		},
		fitMarkerBounds () {
			if (!this.markers?.length) return

			const coords = this.markers.map(item => item.latlng)

			this.$refs.map.mapObject.fitBounds(coords, { maxZoom: 3 })
		}
	}
}
</script>

<style lang="scss" scoped>
.leaflet-map {
  z-index: 1;
}
</style>
