<template>
	<VBottomSheet
		:value="showDialog"
		content-class="v-top-sheet"
		transition="top-sheet-transition"
		@input="$emit('input', $event)"
	>
		<slot />
	</VBottomSheet>
</template>

<script>

import { VBottomSheet } from 'vuetify/lib'

export default {
	name: 'top-sheet',
	extends: VBottomSheet,
	model: {
		prop: 'showDialog',
		event: 'input'
	},
	props: {
		showDialog: Boolean
	}
}
</script>

<style lang="scss">
  .v-top-sheet {
    align-self: flex-start !important;
  }

  .top-sheet-transition {
    &-enter {
      transform: translateY(-100%);
    }

    &-leave-to {
      transform: translateY(-100%);
    }
  }
</style>
