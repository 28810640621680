<template>
	<LineChart
		:chart-data="chartData"
		:chart-options="chartOptions"
	/>
</template>

<script>
import LineChart from '../../../../../../shared/components/chart/line-chart.vue'
import { getProcessCountsCumulative } from '../../../../../../shared/utils/api/processing.js'

export default {
	components: { LineChart },
	props: {
		chartApiParams: Object,
		dateFrom: String,
		dateTo: String
	},
	data () {
		return {
			processesChartData: [],
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false
			}
		}
	},
	computed: {
		chartData () {
			const data = []
			const labels = []
			this.processesChartData?.forEach(process => {
				const [label, value] = Object.entries(process).flat()
				data.push(value)
				labels.push(label)
			})
			return {
				labels,
				datasets: [
					{
						borderColor: '#000',
						data
					}
				]
			}
		},
		displayChart () {
			return !!this.chartData.datasets[0].data.length
		}
	},
	watch: {
		chartApiParams () {
			this.loadProcessCumulativeCounts()
		}
	},
	async created () {
		this.loadProcessCumulativeCounts()
	},
	methods: {
		async loadProcessCumulativeCounts () {
			if (this.chartApiParams.processes) {
				const { data: { processCounts } } = await getProcessCountsCumulative(this.chartApiParams.processes, this.chartApiParams.dateFrom, this.chartApiParams.dateTo)
				this.processesChartData = processCounts
			}
			this.$emit('chart-data', this.displayChart)
		}
	}
}
</script>
